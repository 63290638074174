import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"
import Div100vh from "react-div-100vh"
import * as styles from "../css/index.module.css"

const IndexPage = ({ data }) => (
  <Div100vh>
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title="CIAN MOORE"
      titleTemplate="%s"
      meta={[
        {
          name: "description",
          content: "",
        },
        {
          property: "og:title",
          content: "CIAN MOORE",
        },
        {
          property: "og:description",
          content: "",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: "",
        },
        {
          name: "twitter:title",
          content: "CIAN MOORE",
        },
        {
          name: "twitter:description",
          content: "",
        },
      ].concat([])}
    />
    <div className={styles.container}>
      <div className={styles.wrap}>
        <div>
          <StaticImage
            src="../img/home/1.png"
            className="index-img"
            imgStyle={{ width: "100%", height: "inherit" }}
            draggable="false"
            placeholder="tracedSVG"
            tracedSVGOptions={{
              color: "#000000",
            }}
            alt="Cian Moore"
          />
        </div>
        {data.site.siteMetadata.shop ? (
          <a className={styles.indexshop} href="https://shop.cianmoore.com">
            <StaticImage
              src="../img/home/2.png"
              className="index-img"
              imgStyle={{ width: "100%", height: "inherit" }}
              draggable="false"
              placeholder="tracedSVG"
              tracedSVGOptions={{
                color: "#00b31f",
              }}
              alt="Shop"
            />
          </a>
        ) : null}
        <a className="indexlink">
          <StaticImage
            src="../img/home/3.png"
            className="index-img"
            imgStyle={{ width: "100%", height: "inherit" }}
            draggable="false"
            placeholder="tracedSVG"
            tracedSVGOptions={{
              color: "#c31d03",
            }}
            alt="Work"
          />
        </a>
        <a className="indexlink">
          <StaticImage
            src="../img/home/4.png"
            className="index-img"
            imgStyle={{ width: "100%", height: "inherit" }}
            draggable="false"
            placeholder="tracedSVG"
            tracedSVGOptions={{
              color: "#0545c4",
            }}
            alt="About"
          />
        </a>
        <a className="indexlink">
          <StaticImage
            src="../img/home/5.png"
            className="index-img"
            imgStyle={{ width: "100%", height: "inherit" }}
            draggable="false"
            placeholder="tracedSVG"
            tracedSVGOptions={{
              color: "#f4b421",
            }}
            alt="Contacts"
          />
        </a>
        {/* <Link to="work">
          <StaticImage
            src="../img/home/3.png"
            className="index-img"
            imgStyle={{ width: "100%", height: "inherit" }}
            draggable="false"
            placeholder="tracedSVG"
            tracedSVGOptions={{
              color: "#c31d03",
            }}
          />
        </Link>

        <Link to="about">
          <StaticImage
            src="../img/home/4.png"
            className="index-img"
            imgStyle={{ width: "100%", height: "inherit" }}
            draggable="false"
            placeholder="tracedSVG"
            tracedSVGOptions={{
              color: "#0545c4",
            }}
          />
        </Link>
        <Link to="contact">
          <StaticImage
            src="../img/home/5.png"
            className="index-img"
            imgStyle={{ width: "100%", height: "inherit" }}
            draggable="false"
            placeholder="tracedSVG"
            tracedSVGOptions={{
              color: "#f4b421",
            }}
          />
        </Link> */}
      </div>
    </div>
  </Div100vh>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        shop
      }
    }
  }
`

// IndexPage.propTypes = {
//   data: PropTypes.shape({
//     shop: PropTypes.bool.isRequired,
//   }).isRequired,
// }

export default IndexPage
